/* latin */
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/assets/brandhoff_engineering_custom/Raleway_400_latin.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/assets/brandhoff_engineering_custom/Raleway_500_latin.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/assets/brandhoff_engineering_custom/Raleway_600_latin.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/assets/brandhoff_engineering_custom/Raleway_700_latin.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/assets/brandhoff_engineering_custom/Raleway_800_latin.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.logo img {
  width: 25%;
  padding-top: 32px;
  padding-bottom: 32px;
}

.navbar-brand img {
  width: 18px;
}

.hero-title {
  font-size: 3em;
  font-style: normal;
  font-weight: 400;
  max-width: -moz-max-content;
  max-width: max-content;
}

.section-description {
  max-width: -moz-max-content;
  max-width: max-content;
}

.hero-subtitle {
  color: #000000;
  max-width: -moz-max-content;
  max-width: max-content;
}

h1 {
  font-weight: 400;
}

h2 {
  font-weight: 400;
}

h3 {
  font-weight: 400;
}

h4 {
  font-weight: 400;
}

p {
  text-align: justify;
}

.web-footer {
  min-height: 160px;
}

.section.logo {
  margin-bottom: 6rem;
}

.hero-content:not(.noborder) {
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  background-color: rgba(250, 250, 250, 0.6274509804);
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.75rem;
}

.bgimage {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.75rem;
  margin-bottom: 0rem;
  padding-top: 20rem;
  padding-bottom: 0rem;
  margin-top: 1rem;
}

.split-section-image {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.75rem;
}

.split-section-content.align-top {
  margin-top: 0rem;
}

.form-control {
  background-color: #ffffff;
}

:root {
  --brand-color: #6B397D;
  --primary: #6B397D;
  --primary-color: #6B397D;
  --secondary: #2F98D5;
  --secondary-color: #2F98D5;
  --bg-color: #eef7fc;
  --font-stack: "Raleway", -apple-system, BlinkMacSystemFont,
  "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
  "Droid Sans", "Helvetica Neue", sans-serif;
}